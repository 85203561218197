import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import howto from './howto.gif';


const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(30),
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight:'bold'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(24),
    flexBasis: '100%',
    flexShrink: 0,
    fontWeight:'bold'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    position: 'fixed',
    top: '10% !important',
    left: '10% !important',
    maxWidth: '80%',
    maxHeight: '70vh',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    overflow:'auto',
    maxHeight: '70vh',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  defSection: {
    paddingTop:'10px',
    paddingBottom:'10px'
  },
  howToImg: {
    marginLeft:'auto',
    marginRight: 'auto'
  },
  howToImgContainer: {
    textAlign:'center'
  },
  lukLink: {
    color: '#394151',
    '&:hover': {
      color: '#394151',
    }
  }

});

class InfoModal extends React.Component {
  state = {
  };



  render() {
    const classes = this.props.classes;

    const PARTIAL_DEFS = {
      "fpl": (
        <span>
          <Typography className={classes.defSection}>
            <strong>Federal Poverty Level (FPL)</strong>, 
            also known as the Federal Poverty Guideline, 
            is a national poverty threshold issued each year by the 
            U.S. Department of Health and Human Services (HHS).
          </Typography>
        </span>
      ),
      "smi": (
        <span>
          <Typography className={classes.defSection}>
            <strong>Standard Median Income (SMI)</strong>, 
            also known as the <strong>Area Median Income (AMI)</strong>, 
            is the middle point of income where one-half of households 
            make more than that amount and one-half of households make less. 
            The 2019 SMI for San Diego County was $86,300.
          </Typography>
        </span>
      ),
      "calfresh": (
        <span>
          <Typography className={classes.defSection}>
            <strong>CalFresh </strong> 
            is a nutrition program that can help households 
            buy healthy foods. Nationally, it is known as the 
            <strong> Supplemental Nutrition Assistance Program (SNAP)</strong> and used to be known as the <strong>Food Stamp Program</strong>.
          </Typography>
        </span>
      ),
      "rentBurdened": (
        <span>
          <Typography className={classes.defSection}>
            <strong>Rent Burdened:</strong> According to the 
            U.S. Department of Housing and Urban Development, 
            “families who pay more than 30 percent of their income 
            for housing are considered cost [rent] burdened and may 
            have difficulty affording necessities such as food, clothing, 
            transportation and medical care.”
          </Typography>
        </span>
      ),
      "uninsured": (
        <span>
          <Typography className={classes.defSection}>
            <strong>Uninsured: </strong> 
            Lacking medical insurance, 
            which would cover routine and emergency 
            medical expenses and medicines. According to the 
            U.S. Institute of Medicine, Committee on the Consequences of Uninsurance,
            “evidence shows that uninsured families do not use the health 
            system to the same extent that insured families do. 
            This means that the children are less likely to see a doctor, 
            receive hospital outpatient or emergency care, visit a dentist, 
            or receive prescription medicines than are children who are privately 
            insured or covered by public programs.” 
          </Typography>
        </span>
      ),
      "children_in_poverty": (
        <span>
          <Typography className={classes.defSection}>
            <strong>Children in poverty</strong>: 
            According to 
            <a href="https://aspe.hhs.gov/2019-poverty-guidelines" 
            target="_blank"
            rel="noopener noreferrer"> the HHS Federal Poverty Guidelines: </a>
            Per census tract, children under the age of 12 living below 200% of 
            the Federal Poverty Line (double the FPL).
            <br/>
          <br/>
          <i>Source: ACS 2018</i>
          </Typography>
        </span>
          
      ),
      "childcare_capacity_vs_need": (
        <span>
          <Typography className={classes.defSection}>
          <strong>Childcare need</strong>: 
          Per census tract, the number of children under the age of 12 
          in households making 85% Standard Median Income (SMI).
          According to the California Department of Education, 
          families making 85% SMI are eligible for subsidized child care. 
          The 2019 SMI for San Diego County is $86,300, approximately 300%, or triple the 
          Federal Poverty Level (FPL). 
          <br/>
          <br/>
          <i>Source: ACS 2018</i>
          </Typography>
        </span>
      ),
      "calfresh_eligibility_vs_participation": (
        <span>
          <Typography className={classes.defSection}>
          <strong>CaFresh eligibility vs. participation</strong>: 
          Per census tract, the number of households 
          with at least one child under the age of 18 living below 130% or ~1.3 times 
          the Federal Poverty Level (FPL), as compared to the number of families with
          at least one child under the age of 18 who have received SNAP benefits.

          <br/>
          <br/>
          <i>Source: ACS 2018</i>

         </Typography>
        </span>
      ),
      "family_rent_burden": (
        <span>
          <Typography className={classes.defSection}>
          <strong>Family rent burden</strong>: 
          Per census tract, the median percentage of household income paid towards rent.
          <br/>
          <br/>
          <i>Source: ACS 2018</i>
          </Typography>
        </span>
      ),
      "uninsured_children": (
        <span>
          <Typography className={classes.defSection}>
          <strong>Uninsured children: </strong>
           Per census tract, the percentage of children under the age of 18 uninsured as 
           compared to the percentage of children under the age of 18 insured.

            <br/>
            <br/>
            <i>Source: ACS 2018</i>

           </Typography>
        </span>
      ),
    }

    const FULL_MODALS = {
      "introModal": (
        <span>
          <Typography className={classes.heading}>
            Child Poverty in San Diego
          </Typography>
          <Typography className={classes.defSection}>
          San Diego is known for its beautiful beaches, abundant tourism, and laid-back lifestyle.
          But hundreds of thousands of San Diegans are struggling to make ends meet across the County, many of whom are children.
          This map helps us begin to understand that story.
          </Typography>
          <div className={classes.howToImgContainer}>
            <img className={classes.howToImg} src={howto} alt="San Diego for Every Child Map How To" height="100%" />
          </div>
          <Typography className={classes.defSection}>
          Hover over your own neighborhood, then explore other parts of the County. 
          Was it what you assumed, or did you find it surprising? 
          Use this data to help advocate for your neighbors, near or far. 

          <br/><br/>
          Each layer we present is a choropleth map. Each census tract is shaded in proportion to the full range of values that are represented in this layer.  Therefore, we recommend looking at one layer at a time to get the best result.

          <br/><br/>
          If you have a question / comment, please <br/> <a className={classes.lukLink} href="http://sandiegoforeverychild.org/contact" rel="noopener noreferrer" target="_blank"> <strong>Let us know!</strong></a>
          </Typography>
        </span>
      ),
      "definitions": (
        <span>
          <Typography className={classes.heading}>
            Definitions and Methodology
          </Typography>

          {Object.keys(PARTIAL_DEFS).map(key => (
            <span key={key}>
            {PARTIAL_DEFS[key]}
            <Divider/>
            </span>
          ))}

          <Typography className={classes.defSection}>
            Are we missing something? Did we get something wrong? 
            <a href="http://sandiegoforeverychild.org/contact" rel="noopener noreferrer" target="_blank"> Let Us Know!! </a> 
          </Typography>
         

        </span>
      )
    }

    const MODALS = {...FULL_MODALS, ...PARTIAL_DEFS}
  
    return (
      <div className={classes.root}>
        <Modal
          //aria-labelledby="San Diego Child Poverty intro"
          //aria-describedby="San Diego Child Poverty intro"
          className={classes.modal}
          open={this.props.modalOpen}
          onClose={this.props.handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
        <Fade in={this.props.modalOpen}>
        <div className={classes.modalPaper}>
          <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.handleModalClose}>
            <CloseIcon />
          </IconButton>
          {MODALS[this.props.modalType]}
        </div>
        </Fade>
        </Modal>
      </div>
    );
  }
}

InfoModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoModal);

