import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/Help';
import Link from '@material-ui/core/Link';


// TODO this needs to be combined with MAP Layers.
// NOT DRY AS IS

const DATA_MAP = {
  // MAP LAYERS                             // MAP DATA
  'pct_under_12_under_200_fpl': 'pct_under_12_under_200_fpl',
  'childcare_capacity_fullness_under_12_under_300': 'childcare_capacity_fullness_under_12_under_300',
  'calfresh_participation': 'pct_of_eligible_who_receive',
  'median_gross_rent_pct_hh_income': 'median_gross_rent_pct_hh_income',
  'pct_under_18_uninsured': 'pct_under_18_uninsured',
}

const styles = theme => ({
  root: {
    margin:'8px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexBasis: '100%',
    flexShrink: 0,
  },
  locationInfo: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '90%',
    fontWeight: 'bold',
    
    padding: '2px'
  },


  description: {
    fontSize: theme.typography.pxToRem(13),
    width:'100%'
  },
  modalLinks: {

  },
  expansionPanel: {
    background:"transparent",
    borderTop: '1px solid #394151'
  },
  expansionPanelSummary: {
    padding:"0 0 0 0"
  },
  expansionPanelDetails: {
    paddingLeft:"5px",
    paddingRight:"0px",
    paddingTop:"0px",
    paddingBottom:"0px",
  },
  expandMoreIcon: {
    color:'red'
  }
  
});

class InfoPanel extends React.Component {

  state = {
    expanded: false,
    vintage: this.props.vintage
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  _getValueFromLayerKey = (layerKey) => {
    let accessKey = layerKey.replace(`_${this.state.vintage}`, "")
    accessKey = DATA_MAP[accessKey]
    return accessKey;
  };


  render() {
    const classes = this.props.classes;
    let tileData = (this.props.tileData === null ? [] : this.props.tileData);
    let mapLayers = this.props.mapLayers
    if (tileData.GEOID === undefined) {
      return (
        <div className={classes.root}>
          <Typography className={classes.introText}>
              We will show detailed information here for the area you hover over.
              <br/>Click on <ExpandMoreIcon className={classes.expandMoreIcon} /> to learn more about each metric.
          </Typography>
        </div>
      )
    }
  
    return (
      <div className={classes.root}>
        <Typography className={classes.locationInfo}>
            In {tileData['NEIGHBORHOOD']}, CA:
        </Typography>

        {Object.keys(mapLayers)
          .filter(layerKey => layerKey.includes(this.state.vintage))
          .map(layerKey => (
          <ExpansionPanel 
          elevation={0}
          key={layerKey}
          expanded={this.state.expanded === layerKey}
          className={classes.expansionPanel} 
          onChange={this.handleChange(layerKey)}>                  
            <ExpansionPanelSummary 
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
            //aria-controls="panel1bh-content"
            //id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {mapLayers[layerKey]['sentence_metric'](Math.round(tileData[this._getValueFromLayerKey(layerKey)]))}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.expansionPanelDetails}>
              <Typography className={classes.description}>
                  <strong>Description:</strong><br/>
                  <i>{mapLayers[layerKey]['description']}</i>
                  <br/><br/>
                  <Link className={classes.linkListLink} href="#" onClick={this.props.onModalCall} id="definitions">
                      Definitions and Methodology
                  </Link>
                  
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>            
                        
        ))}
      </div>
    );
  }
}

InfoPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoPanel);

