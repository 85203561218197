import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/Help';





const styles = theme => ({
  root: {
    padding:'8px',
    background: 'white',
    maxHeight:'190px',
    maxWidth:'200px',
    opacity: '0.8'
  },
  heading: {
    fontSize: theme.typography.pxToRem(10),
    flexBasis: '100%',
    flexShrink: 0,
  },
  expandMoreIcon: {
    color:'red'
  }

});

class Legend extends React.Component {
  state = {
    
  };

  


  render() {
    const classes = this.props.classes;
    let tileData = (this.props.tileData === null ? [] : this.props.tileData);
    if (tileData.GEOID === undefined) {
      return (
        <div className={classes.root}>
          <Typography>
              Hover over a neighborhood to learn about child poverty in that area.
          </Typography>
        </div>
      )
    }
  
    return (
      <div className={classes.root}>
        <Typography>
              Darker colored areas show more intense child poverty.<br/>
              Click on <ExpandMoreIcon className={classes.expandMoreIcon} /> in the sidebar to learn more about each metric.
          </Typography>
      </div>
    );
  }
}

Legend.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Legend);

