import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import InfoPanel from './InfoPanel';
import Legend from './Legend';
import InfoModal from './InfoModal';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';


import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';


import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';

import Pluralize from 'react-pluralize'

import './App.css';

import logo from './logo.png'

import debounce from 'lodash/debounce';

import ReactMapGL, {Layer, NavigationControl, FlyToInterpolator} from 'react-map-gl'
import MatGeocoder from 'react-mui-mapbox-geocoder'

import 'mapbox-gl/dist/mapbox-gl.css';


/* SETUP */
const MAPBOX_TOKEN = 'pk.eyJ1Ijoic2RjcC1hZG1pbiIsImEiOiJjanpoZTNqZTEwMGViM2RvOW9sN3l2NXU5In0.bvLEdVftDjZ1vlpazf0YSg'
//const STYLE_URL= "mapbox://styles/sdcp-admin/ck47fts3507pz1cp82nd5y678?fresh=true"
const STYLE_URL = "mapbox://styles/sdcp-admin/clax70m4z000214lgwc5624ad?fresh=true"

let MAP_LAYERS_BASE = {
  "pct_under_12_under_200_fpl": {
    "checkbox_title": "Children in poverty",
    "visible": true,
    "sentence_metric": num => (
      <span> 
        For every 100 children, <strong> 
          <Pluralize singular={'child'} plural={'children'} count={num}/>
        </strong> are experiencing poverty.
      </span>
    ),
    "description": 
      <span>
        This map shows the percentage of children under 12 living in households making 
        less than 2x the Federal Poverty Guideline.
      </span>
  },
  "childcare_capacity_fullness_under_12_under_300": {
    "checkbox_title": "Income-eligibility for childcare",
    "sentence_metric": num => {
        if (num === 0) {
          return <span>
            There are no children in this area eligible for subsidized childcare based on income.</span>
        }
        else {
          return <span> 
            
            There are <strong><Pluralize singular={'income-eligible child'} plural={'income-eligible children'} count={num}/> </strong> for subsidized childcare based on income.
            </span>
        }
      },
    "visible": false,
    "description":
      <span>
        This map shows the number of children that are eligible for childcare assistance based on their household income.
      </span>
  },
  "calfresh_participation": {
    "checkbox_title":"CalFresh eligibility vs participation",
    
    "sentence_metric": num => {
      if (num === -1) {
        return <span>There are no families eligible for CalFresh in this area.</span>
      }
      else if (num === 0) {
        return <span>No families received CalFresh in this area.</span> 
      }
      else {
        return <span>
        For every 100 families with children eligibile for food assistance, <strong><Pluralize singular={'gets'} plural={'get'} count={num}/> CalFresh benefits.</strong>
        </span>
      }
    },
    "visible": false,
    "description":
      <span>
        CalFresh is a nutrition program that can help households buy healthy foods. Nationally, it is known as the Supplemental Nutrition Assistance Program (SNAP), and used to be known as the Food Stamp Program. This map shows under-enrollment in this program.
      </span>
  },
  "median_gross_rent_pct_hh_income": {
    "checkbox_title":"Family rent burden",
    "sentence_metric": num => {
      if (num === -1 || num === 0) {
        return <span>There is no good family rent burden data for this area.</span>
      }
      else {
        return <span>
          A family is likely to pay <strong>{num}%</strong> of their income as rent. 
        </span>
      }
    },
    "visible": false,
    "description":
      <span>
        This map shows the median percentage of household income paid towards rent. According to the U.S. Department of Housing and Urban Development, “families who pay more than 30 percent of their income for housing are considered cost [rent] burdened and may have difficulty affording necessities such as food, clothing, transportation and medical care.
      </span>
  },
  "pct_under_18_uninsured": {
    "checkbox_title":"Uninsured children",
    "sentence_metric": num => {
      if (num === 0) {
        return <span>There are no uninsured children in this area.</span>
      }
      //master['pct_under_18_uninsured'] = (master['Total!!UnInsured!!Under18!!Hi'] / master['Total!!Under18!!Hi'])*100

      else if (num === -1){
        return <span>There are no children under 18 in this area.</span>
      }
      else {
        return <span>
          For every 100 children under 18, <strong><Pluralize singular={'child is'} plural={'children are'} count={num}/> uninsured.</strong></span>
      }
    },
    "visible": false,
    "description":
      <span>
        This map shows the percentage of uninsured children under the age of 18. According to the U.S. Institute of Medicine, Committee on the Consequences of Uninsurance, “evidence shows that uninsured families do not use the health system to the same extent that insured families do. This means that the children are less likely to see a doctor, receive hospital outpatient or emergency care, visit a dentist, or receive prescription medicines than are children who are privately insured or covered by public programs
      </span>
  }

}

let MAP_LAYERS_STORE = {}

for (const idx in MAP_LAYERS_BASE) {
  MAP_LAYERS_STORE[`${idx}_2018`] = { ...MAP_LAYERS_BASE[idx], vintage: "2018" };
  MAP_LAYERS_STORE[`${idx}_2020`] = { ...MAP_LAYERS_BASE[idx], vintage: "2020" };
}

const MAP_LAYERS = MAP_LAYERS_STORE

const VINTAGES = ['2020', '2018']


const geocoderApiOptions = {
    country: 'us',
    proximity: {longitude: -117.1611, latitude: 32.7157},
    bbox: [-117.321899, 32.507488, -116.798203, 33.114231],
    types:'postcode,district,place,locality,neighborhood,address'
  }

const mapViewOptions = {
  transitionDuration: 500,
  maxZoom: 13,
  minZoom: 9
}


const drawerWidth = 320;


const styles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    background: '#394151'
  },
  menuButton: {
    //marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  logoToolbar: {
    minHeight:'64px',
    maxHeight:'64px',
    background: '#394151',
    textAlign: 'center',
    paddingLeft:'5px',
    paddingRight: '5px',
    paddingTop: '15px',
    paddingBottom: '5px'
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    //padding: theme.spacing.unit * 3,
    padding: '0px',
    height: '100vh',
    overflow: 'auto',
  },
  mapContainer: {
    width: '100%',
    height: '100%',
  },
  mapContextGridItem: {
    //height: '100%',
    overflow:'scroll',
    maxHeight:'100vh',
  },
  mapContextSection: {
    
  },
  toolbar: theme.mixins.toolbar,
  layerSelectorInputControlLabel:{
    width:'100%',
    marginBottom: '0px',
    marginTop:'0px',
  },
  layerSelectorInputCheckbox: {
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingRight: '5px',
    color: 'red !important'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  censusLabelFormControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  censusLabel: {
    width: '200px'
  },
  selectEmpty: {
    //marginTop: theme.spacing.unit*2,
  },
  navStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '10px'
  },
  legendStyle: {
    position: 'absolute',
    bottom: 72,
    left: 0,
    padding: '10px'
  },
  appToolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  toolbarContact: {
    fontSize: theme.typography.pxToRem(12),
    color:'white',
    textDecoration: 'none',
    '&:hover': {
      color: 'white',
      textDecoration: 'none'
    }
  }
});


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mapLayers: MAP_LAYERS,
      mapStyle: STYLE_URL,
      vintage: "2020",
      activeFeatureID: 1,
      activeFeatureProperties:{},
      mobileOpen:false,
      modalOpen: false,
      modalType:'introModal',
      viewport: {
        latitude: 32.8941,
        longitude: -117.0948,
        zoom: 9,
        bearing: 0,
        pitch: 0,
        minZoom: mapViewOptions.minZoom,
        maxZoom: mapViewOptions.maxZoom,
        width: window.innerWidth - 300,
        height: window.innerHeight - 20
      }
    };

    this.mapRef = React.createRef();
    this.mapContainer = React.createRef();

  }
  // Lifecycle 
  componentDidMount() {
    window.addEventListener('resize', this._resize);
    this._resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._resize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log('component update')
    this._updateMapVisualState(prevState);
  }

  _handleDrawerToggle = () => {
    let currentMobileOpen = this.state.mobileOpen;
    this.setState({mobileOpen: !currentMobileOpen});
  };

  _resize = debounce(() => {
    this.setState({
      viewport: {
        ...this.state.viewport,
        width: this.mapContainer.current.clientWidth,
        height: this.mapContainer.current.clientHeight
      }
    });
  }, 500);

  tractHighlightLayer = (vintage) => {
    let color = 'red'
    //let vis = vintage === this.state.vintage ? 'visible' : 'none'
    let vis = 'visible'
    let layer = {
      id: `tract_tract_highlight_layer`,
      type: 'line',
      source: 'composite',
      'source-layer': `gen_${vintage}`,
      paint: {
        'line-color': color,
        'line-width': 2
      },
      visibility: vis
    }
    console.log(layer)
    return layer
  };



  _onViewportChange = viewport => {
    if (viewport.zoom > mapViewOptions.maxZoom) {
      viewport.zoom = mapViewOptions.zoom;
    }
    else if (viewport.zoom < mapViewOptions.minZoom) {
      viewport.zoom = mapViewOptions.minZoom;
    }
    this.setState({viewport});
  }

  _setHighlightedFeature = debounce(event => {
    if (event.features && 
      event.features[0]
      &&
      ((event.features[0].sourceLayer === 'gen_2018') || (event.features[0].sourceLayer === 'gen_2020'))
    ) {


      this.setState({
        activeFeatureID:event.features[0].properties.GEOID,
        activeFeatureProperties:event.features[0].properties
      })
    }
  }, 300)

  _onHoverOrOnClick = event => {
      this._setHighlightedFeature(event)
   };

  _updateMapVisualState = (prevState) => {
    let m = this.mapRef.current.getMap();
    if (m.isStyleLoaded()) {
      Object.keys(this.state.mapLayers).forEach((key) => {
        m.setLayoutProperty(key, 'visibility', 'none');
        let visibility = this.state.mapLayers[key].visible === true ? 'visible' : 'none';
        m.setLayoutProperty(key, 'visibility', visibility);
      })
      VINTAGES.forEach((v) => {
        m.setLayoutProperty(`sdcp_tracts_lines_base_${v}`, 'visibility', 'none');
      });
      m.setLayoutProperty(`sdcp_tracts_lines_base_${this.state.vintage}`, 'visibility', 'visible');

    }
  }

  _getActiveLayer = () => {
    let mapLayers = this.state.mapLayers
    console.log(mapLayers)

  }




  _handleVintageChanged = (event) => {
    console.log('v change')
    let mapLayers = this.state.mapLayers
    let vintage = event.target.value;
    // Get active layer
    let activeLayerKey = `pct_under_12_under_200_fpl_${this.state.vintage}`
    Object.keys(mapLayers).forEach((key) => {
      if (mapLayers[key].visible === true) {
        console.log(key)
        activeLayerKey = key
      }
    })

    activeLayerKey = activeLayerKey.replace('2018', vintage)
    activeLayerKey = activeLayerKey.replace('2020', vintage)
    Object.keys(mapLayers).forEach((key) => {
      mapLayers[key].visible = key === activeLayerKey ? true : false;
    });



    this.setState({ vintage: vintage, mapLayers: mapLayers })


   /* console.log(this.state.vintage)
    // Set visibility for vintage
    VINTAGES.forEach((v) => {
      m.setLayoutProperty(`sdcp_tracts_lines_base_${v}`, 'visibility', 'none');
    });
    m.setLayoutProperty(`sdcp_tracts_lines_base_${vintage}`, 'visibility', 'visible');*/
  }

  _handleLayerSelectedChanged = name => event => {
    let mapLayers = this.state.mapLayers
    Object.keys(mapLayers).forEach((key) => {
      mapLayers[key].visible = false;
    });
    mapLayers[event.target.value].visible = event.target.checked;
    this.setState({mapLayers:mapLayers})
  }
  
  _handleModalClose = () => {
    this.setState({modalOpen:false})
  }


  _onModalCall = event => {
    this.setState({
      modalOpen: true,
      modalType: event.currentTarget.id
    })
  }


  _goToGeocoderResult = (result) => {
    // Go to result.

    this.setState({
      viewport: {
        ...this.state.viewport,
        //width: this.props.width || window.innerWidth - 300,
        //height: this.props.height || window.innerHeight,
        width: this.mapContainer.current.clientWidth,
        height: this.mapContainer.current.clientHeight,
        longitude: result.center[0],
        latitude: result.center[1],
        zoom: 13,
        transitionDuration: mapViewOptions.transitionDuration,
        transitionInterpolator: new FlyToInterpolator()
      }
    });
  }

  




  render() {
    const { classes } = this.props;
    const {viewport, 
      mapStyle, 
      activeFeatureID, 
      activeFeatureProperties,
      mobileOpen,
      modalOpen,
      modalType
    } = this.state;
    const filter = ["match", ["get", "GEOID"], [activeFeatureID], true, false];

    

    const drawer = (
      <div>
        <div className={classes.logoToolbar}>
          <img src={logo} alt="San Diego for Every Child logo" height="40px" />
        </div>
        <Divider />
        <Grid container className={classes.mapContextContainer} 
          direction="column" 
          justify="flex-start" 
          alignItems="stretch">
          <Grid item xs={12}>
            <div className={classes.mapContextSection} >
              <MatGeocoder
                inputPlaceholder="Find your neighborhood"
                accessToken={MAPBOX_TOKEN}
                onSelect={result => this._goToGeocoderResult(result)}
                showLoader={true}
                inputPaperProps={{elevation: 0}}
                {...geocoderApiOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12}>

            <div className={classes.mapContextSection} >
              <FormControl component="fieldset" className={classes.censusLabelFormControl}>
                <InputLabel id="vintage-select-label" className={classes.censusLabel}>Select Census (ACS) Year</InputLabel>
                <Select
                  labelId="vintage-select-label"
                  id="demo-simple-select"
                  value={this.state.vintage}
                  label="Select Census (ACS) Year"
                  autoWidth
                  onChange={this._handleVintageChanged}
                >
                  <MenuItem value="2020">2020</MenuItem>
                  <MenuItem value="2018">2018</MenuItem>
                </Select>

              </FormControl>
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <div className={classes.mapContextSection} >
              <FormControl component="fieldset" className={classes.formControl}>

              <FormGroup>
                  {Object.keys(this.state.mapLayers)
                    .filter(layerKey => layerKey.includes(this.state.vintage))
                    .map(layerKey => (
                  <FormControlLabel
                    control={
                      <Radio
                        checked={this.state.mapLayers[layerKey].visible} 
                        onChange={this._handleLayerSelectedChanged(layerKey)} 
                        value={layerKey} 
                        className={classes.layerSelectorInputCheckbox}
                      />
                    }
                        label={this.state.mapLayers[layerKey].checkbox_title}
                    className={classes.layerSelectorInputControlLabel}
                    key={layerKey}
                  />
                ))} 
              </FormGroup>
              
            </FormControl>
            </div>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <div className={classes.mapContextSection} >
              <InfoPanel
                tileData={activeFeatureProperties}
                mapLayers={MAP_LAYERS}
                onModalCall={this._onModalCall}
                vintage={this.state.vintage}
              >
              </InfoPanel>
            </div>

          </Grid>

        </Grid>
      </div>
    );

    const highlightLayer = (<Layer {...this.tractHighlightLayer(this.state.vintage)} filter={filter} />);

    return (
      <React.Fragment>
        <CssBaseline />
       
        <div className={classes.root}>
        <InfoModal 
            modalOpen={modalOpen}
            modalType={modalType}
            handleModalClose={this._handleModalClose}
          >
          </InfoModal>

          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar className={classes.appToolbar}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this._handleDrawerToggle}
                className={classes.menuButton}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                <strong>Child poverty in San Diego</strong>
              </Typography>
              <Typography  noWrap>
                <a className={classes.toolbarContact} href="http://sandiegoforeverychild.org/contact" rel="noopener noreferrer" target="_blank" title="Questions or Comments? Let us know!"> <strong>Contact</strong> </a> 
              </Typography>
            </Toolbar>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor='left'
            open={mobileOpen}
            onClose={this._handleDrawerToggle}
            elevation={0}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            PaperProps={{
              elevation: 0
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container>
              <Grid item xs={12}>
                  <div className={classes.mapContainer} ref={this.mapContainer}>
                    <ReactMapGL
                      {...viewport}
                      ref={this.mapRef}
                      mapStyle={mapStyle}
                      onViewportChange={this._onViewportChange}
                      mapboxApiAccessToken={MAPBOX_TOKEN}
                      onHover={this._onHoverOrOnClick} onClick={this._onHoverOrOnClick} className="main-map">
                    {highlightLayer}
                        <div className={classes.navStyle}>
                          <NavigationControl 
                          showCompass={false}/>
                        </div>
                        <div className={classes.legendStyle}>
                          <Legend tileData={activeFeatureProperties}/>
                        </div>
                    </ReactMapGL>
                  </div>
              </Grid>
            </Grid>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
